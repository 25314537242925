<template>
  <div class="card h-100">
    <div class="card-body">
      <h4 class="card-title">{{ experiment.title }}</h4>
      <p class="lead">{{ experiment.company }}</p>
      <p class="small">{{ experiment.date }}</p>

      <div class="row">
        <div class="col-md-6">
          <h5>Description</h5>
          <p class="card-text">{{ experiment.description }}</p>
        </div>
        <div class="col-md-6">
          <h5 class="h5">Goal: {{ experiment.goal }}: {{ experiment.total }}</h5>
          <h6>Details:</h6>
          <table class="table table-striped table-sm small">
            <tbody>
            <tr>
              <th scope="row">Visitors</th>
              <td>{{ experiment.visitors }}</td>
            </tr>
            <tr>
              <th scope="row">Conversions</th>
              <td>{{ experiment.conversions }}</td>
            </tr>
            <tr>
              <th scope="row">Uplift</th>
              <td>{{ (experiment.uplift*100).toFixed(2) }} %</td>
            </tr>
            <tr>
              <th scope="row">Significant?</th>
              <td>{{ experiment.significant ? 'Yes' : 'No' }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row mb-3">
        <div v-for="variation in experiment.variations" class="col-sm-3" :key="variation.title">
          <div class="card">
            <div class="card-img-top">
              <div v-for="(image, index) in variation.images" class="img-wrapper" :key="index">
                <img :src="img(image.url)" alt="" class="img-fluid">
              </div>
            </div>
            <div class="card-body">
              <h6>{{ variation.title }}</h6>
              <!--<input type="text" name="" v-model="variation.image">-->
              <p v-if="variation.effect" :class="(variation.effect > 0 ? 'text-success' : '') + (variation.winner ? ' text-underlined' : '')">{{ variation.effect }}</p>
            </div>
          </div>
        </div>
      </div>

      <h5>Categories</h5>
      <table class="table">
        <tbody>
        <tr>
          <th scope="row">Pagetype</th>
          <td>{{ experiment.pagetypes.join(', ') }}</td>
        </tr>
        <tr>
          <th scope="row">Device</th>
          <td>{{ experiment.devices.join(', ') }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="card-footer">
      <div class="row">
        <div class="col">
          <ul class="list-unstyled">
            <li v-for="pattern in experiment.patterns" class="badge badge-secondary mt-2" style="margin-right: 5px" :key="pattern">
              {{ pattern }}
            </li>
          </ul>
        </div>
        <div v-if="slot" class="col-auto">
          <router-link to="/roadmaps" class="btn btn-primary">Zur Roadmap</router-link>
        </div>
        <div v-else class="col-auto">
          <button @click="addSlot" class="btn btn-primary">Roadmap-Slot hinzufügen</button>
        </div>
      </div>
    </div>

    <div @click="removeExperiment" class="remove-button">
      <i class="fas fa-times"></i>
    </div>
    <router-link :to="'/experiments/edit/' + experiment.id" class="edit-button">
      <i class="fas fa-pencil"></i>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'Experiment',
    components: {},
    directives: {},
    props: {
      experiment: Object
    },
    data() {
      return {}
    },
    computed: {
      slot() {
        return this.project.slots.find(s => s.type === 'experiments' && s.id === this.experiment.slotId);
      },
      images() {
        return this.experiment.variations.reduce((acc, v) => acc.concat(v.images));
      }
    },
    watch: {},
    methods: {
      removeExperiment() {
        let index = this.project.experiments.find(i => i.id === this.experiment.id);
        if (confirm('Experiment wirklich löschen?')) {
          this.project.experiments.splice(index, 1);
          this.$store.dispatch('project/updateProjectByProp', {prop: 'experiments', data: this.project.experiments})
              .then(res => {
                console.debug(res);
              });
        }
      },
      addSlot() {
        this.project.slots.push({
          id: this.generateId(),
          type: 'experiment',
          title: this.experiment.title,
          devices: this.experiment.devices,
          priority: 1,
          startYear: new Date(this.experiment.startDate).getFullYear(),
          startWeek: new Date(this.experiment.startDate).toISOString().slice(4,6),
          durationWeeks: this.experiment.endDate - this.experiment.startDate,
        });
        this.$router.push("/roadmaps");
      }
    }
  }
</script>

<style scoped lang="scss"></style>