<template>
  <div id="experiments-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link class="btn btn-primary mb-5" to="/experiments/add">Neues Experiment erstellen</router-link>

    <TheorySwitchHint v-if="experiments && project.experiments.length > experiments.length" item="Experimente"></TheorySwitchHint>

    <IntroBanner v-if="!(experiments && experiments.length)" link="/experiments/add"></IntroBanner>

    <div v-if="experiments.length" class="navbar sticky-top navbar-expand-lg navbar-light bg-light rounded mb-4">
      <span class="navbar-brand mb-0 h1">Filter</span>
      <div class="navbar-collapse">
        <ul class="navbar-nav d-flex justify-content-around">
          <li v-for="(filter, fIndex) in Object.keys(experimentFilters)" class="nav-item dropdown" :key="'f' + fIndex">
            <a @click="toggleExperimentFilterDropdown(filter)" class="nav-link dropdown-toggle" style="text-transform: capitalize">{{ filter }}</a>
            <div class="dropdown-menu" :class="filtersOpen.indexOf(filter) >= 0 ? 'show' : ''">
              <span @click="toggleExperimentFilter(filter, 'none')" class="text-link dropdown-item text-muted">Alle entfernen</span>
              <span @click="toggleExperimentFilter(filter, 'all')" class="text-link dropdown-item text-muted">Alle hinzufügen</span>
              <div class="dropdown-divider"></div>
              <div class="scroll-container">
                <div v-for="(option, oIndex) in experimentFilters[filter]" @click="toggleExperimentFilter(filter, option)" class="dropdown-item" :key="'f' + fIndex + 'o' + oIndex">
                  <span class="text-link">{{ option }}</span>
                  <i v-if="appliedFilters[filter].indexOf(option) >= 0" class="far fa-check text-success float-right mt-1"></i>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <div v-if="experiments.length > 0" style="position: sticky; top: 5rem;">
          <h3 class="h5">Sample Total:</h3>
          <p>
            Average Effect: {{ (reducedExperiments.uplift * 100).toFixed(2) }} % <br>
            Success Rate: {{ (reducedExperiments.successRate * 100).toFixed(2) }} %
          </p>

          <ul>
            <li v-for="(attribute, index) in Object.keys(reducedExperiments).map(a => Array.isArray(reducedExperiments[a]) && a !== 'significant' ? a : undefined).filter(a => !!a)"
                :key="index">
              {{ attribute }}
              <ul>
                <li v-for="(option, oIndex) in reducedExperiments[attribute]" :key="'a'+index+'o'+oIndex">{{ option }}</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-9">
        <h3 class="h5 small text-right">Results ({{ filteredExperiments.length }} Experiments)</h3>
        <div class="row pt-3">
          <div v-for="(experiment, index) in experimentsView" :id="experiment.id" class="col-12 mb-4" :key="index">
            <Experiment :experiment="experiment"></Experiment>
          </div>
        </div>
      </div>
    </div>

    <button v-if="experimentsView.length > viewLimit" @click="viewLimit += 30" class="btn btn-secondary btn-block mb-3">30 weitere anzeigen</button>
  </div>
</template>

<script>
// @ is an alias to /src
import Experiment from "../components/Experiment";
import IntroBanner from "@/components/IntroBanner";
import TheorySwitchHint from "@/components/TheorySwitchHint";

export default {
  name: 'Experiments',
  components: {
    TheorySwitchHint,
    IntroBanner,
    Experiment
  },
  data() {
    return {
      viewLimit: 30,
      filtersOpen: [],
      appliedFilters: {},
    }
  },
  computed: {
    experiments() {
      return this.project.experiments
          .filter(experiment => !this.activeTheoryId || experiment.theoryId === this.activeTheoryId);
    },
    experimentFilters() {
      return this.$store.state.experimentFilters;
    },
    filteredExperiments() {
      let view = this.experiments;

      Object.keys(this.experimentFilters).forEach(filter => {
        if (filter === 'uplift') {
          if (this.appliedFilters[filter].indexOf("Positiv") < 0) {
            view = view.filter(obj => obj.uplift <= 0 || !obj.finished);
          }
          if (this.appliedFilters[filter].indexOf("Negativ") < 0) {
            view = view.filter(obj => obj.uplift >= 0 || !obj.finished);
          }
          if (this.appliedFilters[filter].indexOf("Kein Effekt") < 0) {
            view = view.filter(obj => obj.uplift !== 0 || !obj.finished);
          }
        } else if (filter === 'significant') {
          if (this.appliedFilters[filter].indexOf("Ja") < 0) {
            view = view.filter(obj => !obj.significant || !obj.finished);
          }
          if (this.appliedFilters[filter].indexOf("Nein") < 0) {
            view = view.filter(obj => obj.significant || !obj.finished);
          }
        } else {
          view = view.filter(obj => {
            let included = false;
            if (Array.isArray(obj[filter]) && obj[filter].length > 0) {
              obj[filter].forEach(option => {
                included = this.appliedFilters[filter].indexOf(option) >= 0;
              });
            } else if (Array.isArray(obj[filter]) && obj[filter].length === 0) {
              included = this.appliedFilters[filter].length === this.experimentFilters[filter].length;
            } else {
              included = this.appliedFilters[filter].indexOf(obj[filter]) >= 0;
            }
            return included;
          });
        }
      });

      return view;
    },
    experimentsView() {
      return this.experiments.slice(0, this.viewLimit);
    },
    reducedExperiments() {
      let appliedFilters = JSON.parse(JSON.stringify(this.appliedFilters));

      Object.keys(appliedFilters).forEach(filter => {
        appliedFilters[filter] = [];
      });

      if (this.experimentsView.length === 0) {
        return [];
      }

      let successful = 0;
      let nonsig = 0;
      let fail = 0;

      for (let experiment of this.experimentsView) {
        Object.keys(appliedFilters).forEach(filter => {
          if (experiment[filter] && Array.isArray(experiment[filter]) && experiment[filter].length > 0) {

            experiment[filter].forEach(option => {
              if (option !== '' && !appliedFilters[filter].find(f => f === option)) {
                appliedFilters[filter].push(option);
              }
            });
          } else if (filter === 'goal') {
            if (experiment[filter] !== "" && !appliedFilters[filter].find(f => f === experiment[filter])) {
              appliedFilters[filter].push(experiment[filter]);
            }
          }
        });

        if (experiment.significant) {
          if (experiment.uplift > 0) {
            successful++;
          } else {
            fail++;
          }
        } else {
          nonsig++;
        }
      }

      appliedFilters.successRate = successful / (successful + nonsig + fail);
      appliedFilters.uplift = this.experimentsView.reduce((cum, exp) => cum * (1 + exp.uplift), 1);

      return appliedFilters;
    },
  },
  methods: {
    toggleExperimentFilterDropdown(filter) {
      let open = this.filtersOpen;
      if (open.indexOf(filter) >= 0) {
        this.filtersOpen.splice(open.indexOf(filter), 1);
      } else {
        this.filtersOpen.splice(open.indexOf(filter), 1);
        this.filtersOpen.push(filter);
      }
    },
    toggleExperimentFilter(filter, option) {
      if (option === 'none') {
        this.appliedFilters[filter] = [];
      } else if (option === 'all') {
        this.appliedFilters[filter] = [];
        this.appliedFilters[filter] = JSON.parse(JSON.stringify(this.experimentFilters[filter]));
      } else {
        let options = this.appliedFilters[filter];
        if (options.indexOf(option) >= 0) {
          this.appliedFilters[filter].splice(options.indexOf(option), 1);
        } else {
          this.appliedFilters[filter].push(option);
        }
      }
    },
  },
  beforeMount() {
    this.appliedFilters = JSON.parse(JSON.stringify(this.experimentFilters));
  }
}
</script>

<style lang="scss" scoped>
.dropdown-toggle, .dropdown-item {
  cursor: pointer;
}

.dropdown-menu {
  display: none;

  &.show {
    display: block;
  }
}
</style>